<template>
  <v-row class="mt-4">
    <v-col>
      <v-card :loading="loadingGet">
        <v-toolbar color="info" flat dark dense>
          <v-toolbar-title class="white--text font-weight-black">
            # {{ value.id || '--' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />

        <validation-observer ref="observer">
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{}">
                    <validation-provider v-slot="{ errors }" vid="date">
                      <v-text-field
                        v-mask="'##/##/####'"
                        label="Data"
                        :error-messages="errors"
                        autocomplete="off"
                        :disabled="loadingGet"
                        append-icon="mdi-calendar"
                        :value="value.date"
                        @click:append="menuDate = true"
                        @input="update('date', $event)"
                        @blur="formatDateCalendar"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    class="ma-0"
                    @input="menuDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  :value="value.customerId"
                  :disabled="loadingGet"
                  :items="customers"
                  :loading="loadingCustomers"
                  item-value="id"
                  item-text="companyName"
                  label="Cliente"
                  @input="update('customerId', $event)"
                />
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  vid="customerContactId"
                >
                  <v-select
                    :value="value.customerContactId"
                    :loading="loadingCustomerContacts"
                    :disabled="loadingGet || !value.customerId"
                    :items="customerContacts"
                    item-value="id"
                    item-text="name"
                    label="Contato"
                    :error-messages="errors"
                    @input="update('customerContactId', $event)"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <validation-provider
                  v-slot="{ errors }"
                  vid="serviceModalityId"
                >
                  <v-select
                    :value="value.serviceModalityId"
                    :disabled="loadingGet"
                    label="Modalidade de Serviço"
                    :items="serviceModalities"
                    :loading="loadingServiceModalities"
                    item-value="id"
                    item-text="description"
                    :error-messages="errors"
                    @input="update('serviceModalityId', $event)"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="5">
                <validation-provider v-slot="{ errors }" vid="technicians">
                  <v-select
                    :value="value.technicians"
                    :items="technicians"
                    item-value="id"
                    item-text="name"
                    :disabled="loadingGet"
                    multiple
                    label="Técnicos"
                    :error-messages="errors"
                    @input="update('technicians', $event)"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < 2">
                        <v-chip small label>{{ item.name }}</v-chip>
                      </span>
                      <span v-if="index === 2" class="grey--text caption ml-1">
                        (+{{ value.technicians.length - 2 }} outros)
                      </span>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider v-slot="{ errors }" vid="sectors">
                  <v-select
                    :value="value.sectors"
                    :items="sectors"
                    item-value="id"
                    item-text="description"
                    :disabled="loadingGet"
                    multiple
                    label="Setores"
                    :error-messages="errors"
                    @input="update('sectors', $event)"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < 2">
                        <v-chip small label>{{ item.description }}</v-chip>
                      </span>
                      <span v-if="index === 2" class="grey--text caption ml-1">
                        (+{{ value.sectors.length - 2 }} outros)
                      </span>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" vid="details">
                  <span class="subtitle-2">Detalhes</span>
                  <quill-editor
                    :value="value.details"
                    class="editor-details"
                    :options="editorOption"
                    @input="update('details', $event)"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="v-messages theme--light error--text mt-2"
                  >
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-15">
              <v-col cols="12">
                <p class="subtitle-2 mb-2">
                  Checklist
                </p>
                <v-row no-gutters>
                  <v-col md="4" v-for="item in checklistItems" :key="item.id">
                    <v-checkbox
                      class="mt-0"
                      hide-details
                      v-model="checklistItemsModel"
                      :label="item.description"
                      :value="item.id"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-15">
              <v-col>
                <span class="subtitle-2">
                  Horários
                </span>
                <v-btn
                  color="primary"
                  icon
                  small
                  @click="openDialogWorkShift()"
                >
                  <v-icon v-text="'mdi-plus-circle'" />
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col cols="5">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Turno
                        </th>
                        <th class="text-left">
                          Hora início
                        </th>
                        <th class="text-left">
                          Hora final
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-if="!value.workShifts || value.workShifts.length <= 0"
                      >
                        <td colspan="4">
                          <i><small>Nenhum horário adicionado</small></i>
                        </td>
                      </tr>
                      <tr
                        v-for="(item, key) in value.workShifts"
                        v-else
                        :key="key"
                      >
                        <td>{{ workShiftDescription(item.shift) }}</td>
                        <td>{{ item.start }}</td>
                        <td>{{ item.end }}</td>
                        <td class="text-right">
                          <v-btn icon small @click="openDialogWorkShift(key)">
                            <v-icon small v-text="'mdi-pencil'" />
                          </v-btn>
                          <v-btn icon small @click="deleteWorkShift(key)">
                            <v-icon small v-text="'mdi-delete'" />
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <validation-provider v-slot="{ errors }" vid="workShifts">
                  <span class="error--text body-2">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </validation-observer>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="text-left">
              <btn-save :loading="loadingSave" @click="$emit('save')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>

    <dialog-work-shift
      v-model="showDialogWorkShift"
      :edit-work-shift="editWorkShift"
      @add="addWorkShift"
      @edit="changeWorkShift"
    />
  </v-row>
</template>

<script>
import usersApi from '@/api/users'
import checklistItemsApi from '@/api/checklist-items'
import customersApi from '@/api/customers'
import serviceModalitiesApi from '@/api/service-modalities'
import sectorsApi from '@/api/sectors'
import dateFormat from '@/utils/date-format'

import 'quill/dist/quill.snow.css'
import { quillEditor, Quill } from 'vue-quill-editor'

Quill.register(Quill.import('attributors/style/align'), true)

export default {
  components: {
    quillEditor,
    DialogWorkShift: () =>
      import('@/components/dialog/reports/DialogWorkShift'),
  },

  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    formValidation: {
      type: Object,
      default: () => {
        return {}
      },
    },
    loadingSave: {
      type: Boolean,
      default: false,
    },
    loadingGet: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: null,
      menuDate: false,
      loadingCustomers: false,
      loadingCustomerContacts: false,
      loadingServiceModalities: false,
      showDialogWorkShift: false,
      technicians: [],
      customers: [],
      sectors: [],
      customerContacts: [],
      serviceModalities: [],
      checklistItems: [],
      editWorkShiftKey: undefined,
      editWorkShift: undefined,
      checklistItemsModel: [],
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
          ],
        },
      },
    }
  },

  watch: {
    'value.customerId'() {
      this.getCustomerContacts()
    },
    'value.checklistItems'() {
      this.checklistItemsModel = this.value.checklistItems
    },
    checklistItemsModel(value) {
      this.update('checklistItems', value)
    },
    formValidation(val) {
      this.$refs.observer.reset()
      this.$refs.observer.setErrors(val)
    },
    date(date) {
      this.update('date', dateFormat.ptBr(date))
    },
  },

  mounted() {
    this.getReportRelationsData()
  },

  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    async getReportRelationsData() {
      try {
        const [
          techniciansResponse,
          sectorsResponse,
          checklistItemsResponse,
          customersResponse,
          serviceModalitiesResponse,
        ] = await Promise.all([
          usersApi.listTechnicians(),
          sectorsApi.list(),
          checklistItemsApi.list(),
          customersApi.list(),
          serviceModalitiesApi.list(),
        ])

        this.technicians = techniciansResponse.data.technicians
        this.sectors = sectorsResponse.data.sectors
        this.checklistItems = checklistItemsResponse.data.checklistItems
        this.customers = customersResponse.data.customers
        this.serviceModalities = serviceModalitiesResponse.data.modalities
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      }
    },

    async getCustomerContacts() {
      try {
        this.loadingCustomerContacts = true

        if (this.customerContacts.length > 0) {
          this.update('customerContactId', null)
        }

        const response = await customersApi.listContacts(this.value.customerId)
        this.customerContacts = response.data.contacts
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingCustomerContacts = false
      }
    },

    formatDateCalendar() {
      this.date = dateFormat.iso(this.value.date)
    },

    openDialogWorkShift(key = null) {
      if (typeof key === 'number') {
        this.editWorkShiftKey = key
        this.editWorkShift = { ...this.value.workShifts[key] }
      } else {
        this.editWorkShiftKey = {}
        this.editWorkShift = {}
      }

      this.showDialogWorkShift = true
    },

    addWorkShift(newWorkShift) {
      const workShifts = this.value.workShifts || []
      workShifts.push(newWorkShift)
      this.update('workShifts', workShifts)
      this.showDialogWorkShift = false
    },

    changeWorkShift(newWorkShift) {
      const workShifts = this.value.workShifts || []
      workShifts[this.editWorkShiftKey] = newWorkShift
      this.update('workShifts', workShifts)
      this.showDialogWorkShift = false
    },

    deleteWorkShift(key) {
      const workShifts = this.value.workShifts
      delete workShifts[key]
      const newWorkShifts = workShifts.filter(e => e)
      this.update('workShifts', newWorkShifts)
    },

    workShiftDescription(shift) {
      switch (shift) {
        case 1:
          return 'Manhã'
        case 2:
          return 'Tarde'
        case 3:
          return 'Noite'
      }
    },
  },
}
</script>

<style>
.editor-details {
  height: 300px;
}
.editor-observations {
  height: 150px;
}
</style>
